
.wrapper {
  display: inline-flex;
  position: relative;
  flex-direction: column;
}

.searchBox {
  padding: 10px;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 14px;
  color: #607d8b;
  cursor: pointer;
}

.displayArea {
  position: relative;
  left: 1px;
  top: 8px;
  max-width: 200px;
  min-height: 100px;
  background: #f5f0f0;
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px;
  padding: 5px;
}

.listItem {
  padding: 5px 0;
  display: block;
  border-bottom: 1px solid #37474f;
  color: #ff9800;
}

.listItem:last-child {
  border: none;
}